<template>
  <div class="pa-2 ">
    <page-card
        cardTitle="Your Wedding Wall"
        cardTitleIcon="mdi-wall"
    >

        <v-card-text>
          <h2>All About Your Wedding Wall</h2><br>

          <p>The Wedding Wall is where your guests and absent friends can share in the celebrations and contribute photos, messages and more.
            It is your own interactive ‘wedsite’!
          </p>
          <p>Your Wedding Wall is located on a secure section of the CherryTop Weddings website. The precise address and the password to access
            the page will be published on Page 11 of your booklet, so only you and your guests will be able to find it.
          </p>
          <p>Some of the content of your Wedding Wall depends on which activities you have chosen. For example, the ‘Limericks’,
            ‘Words of Wisdom’ and ‘Picture This’ activities all involve the guests submitting their completed activities to the Wedding Wall.
            If you have not chosen these activities, they will simply not appear on your Wall.
          </p>

          <p>‘The Wedding Album’ and ‘The Guest Book’ will always feature on your Wall.</p>

          <div class="text-decoration-underline">
            The Wedding Album
          </div>
          <p>Where guests and absent friends can upload photos of you, themselves or each other enjoying the event.</p>

          <div class="text-decoration-underline">
            The Guest Book
          </div>
          <p>Where guests and absent friends can upload comments and messages to you.</p>

          <div class="text-decoration-underline">
            Live Stream
          </div>
          <p>If selected, where absent friends can watch the live video feed of your wedding. </p>

          <div class="text-decoration-underline">
            Limericks
          </div>
          <p>Where guests can upload photos of the limericks they have created in the booklet. </p>

          <div class="text-decoration-underline">
            Words of Wisdom
          </div>
          <p>Where guests can upload photos of their ‘wise words’ they have completed in the booklet. </p>

          <div class="text-decoration-underline">
            Picture This
          </div>
          <p>Where guests can upload the photos of their drawings as part of this activity in the booklets.</p>


          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="submit"
              class="pa-4"
          >

            <h2>Let’s Start Personalising Your Wedding Wall</h2><br><br>


            <h3>1.	Wedding Wall title</h3><br>

            <p>
              Your names are automatically inserted as are the design elements from your chosen theme.
              You choose your Wedding Wall title though. You can call it your Love Story, your Wedding Wall, your Wedsite,
              or absolutely anything you like! Please enter the text below:
            </p>
            <br>

            <v-text-field
                v-model="weddingWallTitle"
                :counter="120"
                label="Title"
                :rules="required120Rules"
            />
            <br>

            <h3>2.	Home Page media</h3>
            <br>
            <p>
              On your home page, you can also choose an image, video or carousel/slideshow of you as a couple. Once again,
              this is entirely up to you what you choose.
            </p>
            <br>

            <p>Please choose one option below:</p><br>

            <v-select
                label="Home Page Media"
                v-model="weddingWallHomeMediaType"
                :items="homeMediaItems"
                :rules="homePageMediaRules"
            />
            <br>

            <image-upload-wedding-wall
                ref="imageUpload"
                fileInputLabel="Choose Wedding Wall home page image"
                v-if="weddingWallHomeMediaType === 'photo-uploaded'"
                class="mb-8"
            />

            <h3>3.	Live Stream</h3><br>

            <p>Would you like the capability to live stream the event to your Wedding Wall?   Please be aware that you will
              need to arrange the live stream service separately with a streaming provider who will provide the link that is
              required to stream the event on your Wedding Wall.  All we are providing is a space on your Wedding Wall to display the
              live stream video. For more details on how you can live stream your wedding,
              check out <a class="grey--text" href="https://www.hitched.co.uk/wedding-planning/organising-and-planning/how-to-live-stream-your-wedding/" target="_blank"> www.hitched.co.uk.</a>
            </p>


            <br>


            <v-textarea
                label="Video live feed embed code"
                v-model="weddingWallLiveFeedEmbedCode"
                filled
                auto-grow
                clearable
            />

            <div class="mt-8">
              <v-btn
                  :disabled="!valid"
                  type="submit"
                  class="mr-4"
              >
                Save
              </v-btn>
              <v-btn @click="clear" class="mr-4">Clear</v-btn>
            </div>

          </v-form>

        </v-card-text>

    </page-card>
  </div>
</template>


<script>
import ImageUploadWeddingWall from "@/components/ImageUploadWeddingWall";
import PageCard from "@/components/PageCard";
import {form} from "@/mixins/form";

export default {
  name: "WeddingWall",
  components: {
    ImageUploadWeddingWall,
    PageCard
  },
  mixins: [form],
  data() {
    return {
      valid: true,
      homeMediaItems: [
        {
          text: "Carousel of photos from activities",
          value: "photos-activities"
        },
        {
          text: "Single photo (upload)",
          value: "photo-uploaded"
        },
        // {
        //   text: "Video (upload)",
        //   value: "video-uploaded"
        // },
      ],
      homePageMediaRules: [
        v => !!v || 'A Home Page Media selection is required'
      ],
    }
  },
  computed: {
    weddingWallTitle: {
      get: function () {
        return this.$store.state.weddingWall.title
      },
      set(value) {
        this.$store.commit("setWeddingWallTitle", value)
      }
    },
    weddingWallHomeMediaType: {
      get: function () {
        return this.$store.state.weddingWall.homeMediaType
      },
      set(value) {
        this.$store.commit("setWeddingWallHomeMediaType", value)
      }
    },
    weddingWallLiveFeedEmbedCode: {
      get: function () {
        return this.$store.state.weddingWall.liveFeedEmbedCode
      },
      set(value) {
        this.$store.commit("setWeddingWallLiveFeedEmbedCode", value)
      }
    },
  },
}
</script>
