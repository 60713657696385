<template>
  <div>

    <v-file-input
        v-model="imageFile"
        accept="image/png, image/jpeg, image/bmp"
        :label="fileInputLabel"
        prepend-icon="mdi-camera"
        @change="upload($event)"
        :rules="fileInputRules"
        :clearable=false
        :disabled="fileInputDisabled"
    ></v-file-input>

    <v-progress-circular
        v-if="uploading && !uploadEnd"
        :size="100"
        :width="15"
        :rotate="360"
        :value="progressUpload"
        color="primary">
      %
    </v-progress-circular>

    <v-card v-if="showPreviewImg" color="grey lighten-5" class="mt-4 pa-4">
      <img
          v-if="showPreviewImg"
          :src="downloadURL"
          style="max-width: 600px; width: 100%;"
          alt="Preview of uploaded image"
      />
      <div>
        <v-btn
            dark
            color="error"
            @click="deleteImage()"
        >
          Delete
          <v-icon
              right
              aria-hidden="true">mdi-delete
          </v-icon>
        </v-btn>
      </div>
    </v-card>

    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
    >
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<script>

// Relevant info: https://stackoverflow.com/questions/63446829/how-to-reset-vuetifys-v-input-file

import firebase from "firebase";
import {firestore} from "@/mixins/firebase";
import {form} from "@/mixins/form";

export default {
  name: "ImageUpload",
  mixins: [form, firestore],
  props: {
    fileInputLabel: {
      type: String,
      default: "Add a photo for this page"
    },
  },
  data() {
    return {
      storageRef: firebase.storage().ref(),
      snackbar: false,
      snackbar_text: 'Image uploaded.',
      timeout: 2000,
      progressUpload: 0,
      uploadTask: '',
      uploading: false,
      uploadEnd: false,
      imageFile: null,
      fileInputRules: [
        (v) => {
          if (!this.showPreviewImg) {
            return !!v || 'Image is required'
          } else {
            return true
          }
        }
      ]
    }
  },
  methods: {
    upload(e) {
      if (typeof e !== "undefined" && e !== null) {
        // Upload new image to Firebase.
        const file = this.imageFile
        const fileId = Math.floor(Date.now())
        this.fileName = `images/${this.sourceOrderId}/user_images/${fileId}_${file.name}`
        this.uploading = true
        this.uploadTask = this.storageRef.child(this.fileName).put(file)
        this.updateAppStateInFirestore()
      }
    },
    deleteImage() {
      if (typeof this.fileName !== "undefined" &&
          this.fileName !== "" &&
          this.filename !== null)
      {
        // console.log("this.fileName:", this.fileName)
        this.fieldValueChanged()
        this.storageRef.child(this.fileName)
            .delete()
            .then(() => {
              this.uploading = false
              this.uploadEnd = false
              this.downloadURL = ''
              this.fileName = ''
              this.imageFile = null
              this.updateAppStateInFirestore()
            })
            .catch((error) => {
              console.error(`file delete error occurred: ${error}`)
            })
      }
    }
  },
  computed: {
    sourceOrderId() {
      return this.$store.state.order.sourceOrderId
    },
    downloadURL: {
      get: function () {
        return this.$store.state.weddingWall.homeUploadedImage.paths.original
      },
      set(value) {
        this.$store.commit("setWeddingWallUploadedImagePath", value)
      }
    },
    fileInputDisabled() {
      return this.fileName !== ""
    },
    fileName: {
      get: function () {
        return this.$store.state.weddingWall.homeUploadedImage.filename
      },
      set(value) {
        this.$store.commit("setWeddingWallUploadedImageFilename", value)
      }
    },
    showPreviewImg() {
      if (this.uploadEnd) {
        return true
      }
      if (this.$store.state.weddingWall.homeUploadedImage.filename !== "") {
        return true
      }
      return false
    }
  },
  watch: {
    uploadTask: function () {
      this.uploadTask.on('state_changed', sp => {
            this.progressUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
          },
          null,
          () => {
            this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              this.uploadEnd = true
              this.downloadURL = downloadURL
              this.$emit('downloadURL', downloadURL)
              this.snackbar = true
              this.updateAppStateInFirestore()
            })
          })
    }
  }
}
</script>

<style>
  input[type="file"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }
</style>
